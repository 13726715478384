import { FILTERS_KEY, FILTERS_KEY_CTA } from 'redux/keys';
import { get, isEmpty, isNull, map } from 'lodash';
import { KeyValueType, ReduxFilter, CTAFilterState } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const CTA_FILTER_PATH = [FILTERS_KEY, FILTERS_KEY_CTA];

export const getRecords_CTAFilterState = (state: any): CTAFilterState => {
  return get(state, CTA_FILTER_PATH);
};

export const getCTADashboardFilters = (state: any) => {
  const filterValues: KeyValueType<unknown> = (getRecords_CTAFilterState(
    state
  ) as unknown) as KeyValueType<unknown>;

  const activeFilters: any = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (filterName): ReduxFilter<CTAFilterState> => {
      switch (filterName) {
        case 'notStarted':
          return {
            filter: {
              property: 'reportedCosts',
              values: '',
              comparator: (item: number) => {
                return item === 0;
              },
            },
            operator: 'and',
          };

        case 'archived':
          return {
            filter: {
              property: 'statusId',
              values: filterValues[filterName] !== undefined ? filterValues[filterName] : '',
            },
            operator: 'and',
          };

        case 'status':
          return {
            filter: {
              property: 'statusId',
              values: filterValues[filterName] !== undefined ? filterValues[filterName] : '',
            },
            operator: 'and',
          };

        case 'firstSubmission':
          return {
            filter: {
              property: 'firstSubmitted',
              values: '',
              comparator: (item: string) => {
                if (filterValues[filterName] === 1) {
                  return !isEmpty(item);
                } else if (filterValues[filterName] === 2) {
                  return isNull(item);
                }
                return true;
              },
            },
            operator: 'and',
          };

        default:
          return {
            filter: {
              property: filterName,
              values: filterValues[filterName] !== undefined ? filterValues[filterName] : '',
            },
            operator: 'and',
          };
      }
    }
  );

  return filters;
};
