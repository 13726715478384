import { connect } from 'react-redux';
import { CTAHotButtonsComponent } from './CTAHotButtons.component';
import {
  setCTAFilterValue,
  resetCTAFilterValue,
  clearCTAFilters,
} from 'redux/filters/ctadashboardfilter';
import { getRecords_CTAFilterState } from 'redux/filters/_selectors';
import { getCurrentCTAFiscalYear } from 'redux/common/_selectors';
import { filter } from 'lodash';
import { len as length } from 'util/objectUtility';
import { getRecords_CTADistrictRecord } from 'redux/cta/ctadashboard.selectors';
import { CTADistrictRecord } from 'types';
import { CtaFiltersComponent } from './CTAFilters.component';

export const mapStateToProps = (state: any, ownProps: any) => {
  const allRecords = getRecords_CTADistrictRecord(state);
  const filterState = getRecords_CTAFilterState(state);
  const year = getCurrentCTAFiscalYear(state);

  const needsReview = (item: CTADistrictRecord) => {
    return item.needsReview;
  };
  const notStarted = (item: CTADistrictRecord) => {
    return item.reportedCosts === 0;
  };

  const filterLengths = {
    needsReviewCount: length(filter(allRecords, needsReview)),
    notStartedCount: length(filter(allRecords, notStarted)),
  };

  return { year, filterState, filterLengths };
};

const dispatchToProps = {
  setCTAFilterValue,
  resetCTAFilterValue,
  clearCTAFilters,
};

export default connect(mapStateToProps, dispatchToProps)(CTAHotButtonsComponent);

export const CtaFilter = connect(mapStateToProps, dispatchToProps)(CtaFiltersComponent);
