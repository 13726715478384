import CTAHotButtonsComponent, { CtaFilter } from './CTAHotButtons';
import FilterIconButton from 'components/Common/Filters/FilterIconButton';
import HotYear from 'components/Common/HotYear';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect } from 'react';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import Table from 'components/Common/Table';
import { CTADistrictRecord } from 'types/cta';
import { FiscalYear, ReduxFilter } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { Link, useHistory } from 'react-router-dom';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { insertDollarSign, formatMoney } from 'util/moneyHelpers';
import { isApplicaionYearValid } from 'util/global';
import { renderData, prop, compose, getDate } from 'util/objectUtility';

type CTADashboardProps = {
  isFetching: boolean;
  ctaRecords: CTADistrictRecord[];
  filters: ReduxFilter<any>[];
  loadCTADistrictRecords: Function;
  currentCTAYear: FiscalYear;
};

export const CTADashboardComponent: React.FC<CTADashboardProps> = (props) => {
  const {
    isFetching,
    ctaRecords,
    loadCTADistrictRecords,
    filters,
    currentCTAYear,
  } = props;

  // -- dependencies --
  const history = useHistory();

  // -- local state --
  const [filteredData, setFilteredData] = useState<CTADistrictRecord[]>([]);
  const [filterOpen, setFiltersOpen] = useState<boolean>(false);
  const [currentYear, setCurrentYear] = useState<number>(-1);
  const [activeYear, setActiveYear] = useState<number>(-1);

  useEffect(() => {
    setCurrentYear(currentCTAYear.year);
    setActiveYear(currentCTAYear.year);
  }, [currentCTAYear]);

  // -- lifecycle --
  useEffect(() => {
    if (isApplicaionYearValid(activeYear)) {
      loadCTADistrictRecords(activeYear).catch((err: any) =>
        console.error(err)
      );
    }
  }, [loadCTADistrictRecords, activeYear]);

  // -- display logic --
  const getDisplayData = (name: keyof CTADistrictRecord) => (
    row: CTADistrictRecord
  ) => {
    return renderData(prop(name, row));
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const renderEditIcon = (row: CTADistrictRecord) => {
    return !false ? (
      <IconButton
        name="pencil"
        onClick={() => {
          history.push(`/cta/district-reporting/${row.ctaId}`);
        }}
        tooltip="Edit"
      />
    ) : (
      renderData(null)
    );
  };

  const CTA_DASHBOARD_HEADERS: TableColumnProps[] = [
    {
      name: 'CDE ID',
      cell: getDisplayData('cdeId'),
      selector: prop('cdeId'),
      sortable: true,
      width: '10rem',
    },
    {
      name: 'Name',
      cell: getDisplayData('schoolDistrictName'),
      selector: prop('schoolDistrictName'),
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'DCTS',
      cell: compose((bool: boolean) => (bool ? 'Y' : 'N'), prop('dcts')),
      selector: prop('dcts'),
      sortable: true,
      width: '7rem',
    },
    {
      name: 'Costs Incurred',
      cell: getDisplayData('year'),
      selector: prop('year'),
      sortable: true,
      width: '14rem',
    },
    {
      name: 'Contact',
      cell: getDisplayData('contactName'),
      selector: prop('contactName'),
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Status',
      cell: getDisplayData('status'),
      selector: prop('status'),
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Non-Opt. Reimb. Est',
      cell: compose(insertDollarSign, formatMoney, prop('reportedCosts')),
      selector: prop('reportedCosts'),
      sortable: true,
    },
    {
      name: 'First Submission',
      cell: compose(renderData, getDate, prop('firstSubmitted')),
      selector: prop('firstSubmitted'),
      sortable: true,
    },
    {
      name: 'Needs Review',
      cell: compose(renderCircle, prop('needsReview')),
      selector: prop('needsReview'),
      sortable: true,
      width: '14rem',
    },
    {
      name: 'Access',
      cell: renderEditIcon,
      width: '5%',
    },
  ];

  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <Paper>
        <h2 className="program__title">CTA Reporting Dashboard</h2>
        <Instruction title="" hideTitle>
          <p>
            The CTA <Link to="/cccs-reports">Reporting Dashboard</Link> details
            the status and costs incurred for each approved district you are
            authorized to view for the indicated reporting year. Each row
            corresponds to a specific District or Charter School’s CTA report.
            Enter text in the ‘Search’ bar to find a specific report, use the
            column carrots to sort, or click on the filter button to the right
            of the bar to sort by indicated fields in the dropdown menu. The
            DCTS column indicates whether a district includes a Designated
            Career and Technical School. Click on the pencil icon to edit the
            information provided. Alternatively, click on the buttons beneath
            the search bar to view archived reports for the preceding two years
            and identify reports in need of administrative review.
          </p>
          <p>
            <span className="u-color-red">Note</span>: Data may only be edited
            for reports with a status of ‘Open’ or ‘Information Needed.
          </p>
        </Instruction>
      </Paper>
      <Paper>
        <HotYear
          title="Select Reporting Year"
          year={currentYear}
          activeYear={activeYear}
          prefix="Cost Incurred"
          handleClick={setActiveYear}
        />
      </Paper>
      <div className="u-flex">
        <FlexGroup>
          <Paper>
            <FlexRow>
              <SearchBarFilter
                shouldReset={true}
                data={ctaRecords}
                isFetching={isFetching}
                label="Search"
                setFilteredData={setFilteredData}
                headers={CTA_DASHBOARD_HEADERS}
              />
              <FilterIconButton
                count={filters.length}
                onClick={() => {
                  setFiltersOpen(!filterOpen);
                }}
              />
            </FlexRow>
            <CTAHotButtonsComponent />
            <FlexRow>
              <FlexGroup>
                <Table columns={CTA_DASHBOARD_HEADERS} data={filteredData} />
              </FlexGroup>
            </FlexRow>
          </Paper>
        </FlexGroup>
        {filterOpen && (
          <div className="u-filters">
            <CtaFilter />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
